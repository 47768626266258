<template>
  <div id="bodyContainer">
    <div class="login">
      <p class="login-p" @click="$router.push('Cell')">return</p>
      <div class="login-hunter">
        <div class="login-div">
          <h2>Plantorganelle hunter</h2>
          <p class="login-div-p">A tool to automatically identity plant organelle <br>
            identification and segmentation from epidermal <br>
            micrographs.
          </p>
          <el-form class="login-div-form" :model="LoginForm" :rules="rulesTwo" ref="LoginValidateForm">
            <el-form-item prop="Username">
              <el-input class="login-div-input" placeholder="Please input Username" type="text" v-model="LoginForm.Username"></el-input>
            </el-form-item>
            <el-form-item prop="Password">
              <el-input class="login-div-input" show-password placeholder="Please enter the password" type="text" v-model="LoginForm.Password"></el-input>
            </el-form-item>
            <el-form-item>
              <div class="login-div-button">
                <el-button @click="loginSubmitForm('LoginValidateForm')">Login</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "index",
  data(){
    return {
      LoginForm:{
        Username:'',
        Password:''
      },
      //表单验证
      rulesTwo:{
        Username:[{required:true,message:'This item cannot be empty',trigger:'blur'}],
        Password:[{required:true,message:'This item cannot be empty',trigger:'blur'}]
      },
      //保存不加密密码
      password:''
    }
  },
  methods:{
    //表单提交
    loginSubmitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCAYjJNTDWSTJ8RBnZ9hp7AS8eF\n' +
              'JvxpuZYTqGjD8qak45DKkAhpi7SNXDNbfDXNbUFeH465hwQIznS5fHdWgUAoqByG\n' +
              'jHoYu1jRhDx72EgKhuvDQ3sxwLJr0Ynx1f1Ny9yt8wtLaVc9KcJ8m/pHmmAGz+xf\n' +
              '7rzZ8dJvnee+OHMkNwIDAQAB'
          this.password=this.LoginForm.Password
          let $encryptedData = this.$encryptedData(publicKey,this.LoginForm.Password);
          this.LoginForm.Password=$encryptedData
          this.axios.post('/auth/login',this.LoginForm).then(data=>{
            window.sessionStorage.setItem('assign__token',data.data.token)
            this.$message.success('登录成功^.^');
            this.LoginForm=Object.assign({},'')
            this.$router.push('/back')
          }).catch(()=>{
            this.LoginForm.Password=this.password
            this.$message.error('登录失败,用户名或密码错误！！！');
          })
        } else {
          return false
        }
      });
    }
  }
}
</script>

<style lang="scss">
body{
  margin: 0;
  padding: 0;
}
#bodyContainer{
  width: 100%;
  height:100vh;
  min-width:1200px;
  min-height: 600px;
  background: url("../../assets/loginImage/login.png") no-repeat 0 0/cover;
  .login{
    width: 821px;
    height: 100vh;
    position: relative;
    background: rgba(255,255,255,.9);
    .login-p{
      top: 28px;
      left: 37px;
      color: #3d6d27;
      cursor: pointer;
      position: absolute;
      font-weight: bold;
      text-decoration: underline;
    }
    .login-hunter{
      height: 100vh;
      width: 821px;
      display: flex;
      align-items: center;
      justify-content: center;
      .login-div{
        width: 754px;
        height:716px;
        margin-top:80px;
        margin-left:170px;
        .login-div-form{
          margin-top: 50px;
        }
        .login-div-input{
          .el-input__inner{
            width: 752px;
            height: 102px;
            border: 0;
            border-radius: 0;
            font-size: 30px;
            border-left: #3d6d27 15px solid;
          }
        }
        .login-div-button{
          text-align: center;
          margin-top: 80px;
          .el-button{
            width: 300px;
            height: 90px;
            border: 0;
            color: white;
            border-radius: 0;
            font-size: 30px;
            background: #3d6d27;
          }
        }
        .login-div-p{
          margin-top: 50px;
        }
      }
    }
  }
}
</style>
